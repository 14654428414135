
/**
 * User Profile view.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Vue from "vue";
import { Component } from "vue-property-decorator";

import { mapActions, mapGetters } from "vuex";
import NewUserForm from "@/components/Users/NewUserForm.vue";
import TranslateDropdown from "@/components/Translation/TranslateDropdown.vue";
import ModelUser from "@/model/ModelUser";
import UserActions from "@/components/Users/Profile/UserActions.vue";
import TableHeader from "@/components/tableHeader.vue";
import ThemeToggle from "@/components/Theme/ThemeToggle.vue";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "Profile",
  components: {
    NewUserForm,
    TranslateDropdown,
    UserActions,
    TableHeader,
    ThemeToggle
  },
  computed: {
    ...mapGetters(["getCurrentUser", "isUserAdmin"])
  },
  methods: {
    ...mapActions(["getUserInfo"])
  }
})
export default class Profile extends Vue {
  //Vuex
  readonly getCurrentUser!: ModelUser;
  readonly isUserAdmin!: boolean;

  private getUserInfo!: Function;
  //Data
  updateStatus: null | ActionMessage = null;

  // Methods
  updateMyProfile(value: ActionMessage) {
    this.updateStatus = value;
  }

  showPhoneNumber(phone: string) {
    if (phone !== null) {
      if (phone.length >= 7) {
        phone = phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6, 12);
      } else if (phone.length >= 3) {
        phone = phone.substr(0, 3) + "-" + phone.substr(3, 3);
      } else {
        phone = phone.substr(0, 3);
      }
    } else {
      phone = "Update number";
    }
    return phone;
  }

  openEditModal() {
    this.updateStatus = null;
    this.$bvModal.show("userUpdateItSelf");
  }

  resetUserDetails() {
    this.getUserInfo(this.$keycloak.tokenParsed?.sub);
  }
}
