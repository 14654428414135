
/**
 * Toggle themes from dark to any other
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

@Component({
  name: "ThemeToggle",
  methods: {
    ...mapActions("Theme", {
      TOGGLE_THEME: "TOGGLE_THEME"
    })
  },
  computed: {
    ...mapGetters("Theme", {
      CURRENT_THEME: "GET_CURRENT_THEME"
    })
  }
})
export default class ThemeToggle extends Vue {
  /*----------  Theme toggle to the store  ----------*/
  private TOGGLE_THEME!: Function;
  private CURRENT_THEME!: string;

  theme = true;

  created() {
    this.theme = this.CURRENT_THEME === "dark";
    let bodyEl: HTMLBodyElement | null = document.querySelector("body");
    bodyEl?.setAttribute("data-theme", this.CURRENT_THEME);
  }

  handleThemeChange() {
    let themeName = this.theme ? "dark" : "light";
    let bodyEl: HTMLBodyElement | null = document.querySelector("body");
    bodyEl?.setAttribute("data-theme", themeName);
    this.TOGGLE_THEME(themeName);
  }
}
