
/**
 * UserActions all possible user actions view.
 *
 * @author Reflect-Media <leamsigc@ismael.com>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Vue from "vue";
import { Component } from "vue-property-decorator";

import ibomadeUserService from "@/services/ibomadeUserService";
import ModelUser from "@/model/ModelUser";

@Component({
  name: "UserActions"
})
export default class UserActions extends Vue {
  readonly getCurrentUser!: ModelUser;

  currentUserId: string = "";
  resetStatusMessage: object | null = null;
  created() {
    this.currentUserId = this.$keycloak.tokenParsed?.sub || "";
  }

  /*****  HelperFunction ******/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("users.profile.confirmModal.confirmModalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action(value);
        }
      });
  }
  /*****  Methods ******/

  handleUserResetPassword() {
    this.resetStatusMessage = null;
    this.$emit("resetPasswordStatus", this.resetStatusMessage);

    this.confirmModal((this as any).$t("users.profile.confirmModal.confirmModalMessage"), async () => {
      let resetPasswordStatus = await ibomadeUserService.resetOwnCredentials(this.currentUserId);
      if (resetPasswordStatus === 204) {
        this.resetStatusMessage = {
          class: "success",
          msg: this.$t("users.profile.userResetPasswordSuccessMessage")
        };
        this.$emit("resetPasswordStatus", this.resetStatusMessage);
      } else {
        this.resetStatusMessage = {
          class: "danger",
          msg: this.$t("users.profile.userResetPasswordErrorMessage")
        };
        this.$emit("resetPasswordStatus", this.resetStatusMessage);
      }
    });
  }
  hanldeUserUpdateModal() {
    this.$emit("openEditModal");
  }
}
